<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Riwayat Diklat </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Diklat -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Diklat</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  v-model="editedItem.latihanStruktural.id"
                  :items="refDiklat"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal / Jam Pelaksanaan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal / Jam Pelaksanaan</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.tempat"
                  hint="Tempat Pelaksanaan"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAwalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.pelaksanaan.awal"
                      hint="Tanggal Awal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.pelaksanaan.awal"
                    @input="tglAwalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAkhirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.pelaksanaan.akhir"
                      hint="Tanggal Akhir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.pelaksanaan.akhir"
                    @input="tglAkhirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.pelaksanaan.jp"
                  hint="Jam"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Penyelenggara -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Penyelenggara</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="editedItem.penyelenggara"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nomor/ Tanggal Ijazah -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nomor/ Tanggal Ijazah</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.nomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglIjazahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.tanggal"
                      hint="Tanggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.tanggal"
                    @input="tglIjazahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.tahun"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kualifikasi / Nilai Rata-rata -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kualifikasi / Nilai Rata-rata</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-model="editedItem.kualifikasi.id"
                  hint="Kualifikasi"
                  persistent-hint
                  :items="refKualifikasi"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedItem.nilai"
                  hint="Nilai"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>

          <!-- Upload File -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-5">
                <span>Upload</span>
              </v-col>
              <v-col cols="5">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="onUpload"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()">
              SIMPAN
            </v-btn>
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/asn/rwdiklat/viewRwdiklat";
import modal_editState from "@/store/asn/rwdiklat/modal_edit";

export default {
  name: "rwdiklatModalEdit",

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refDiklat = await getRef.LatihanStruktural(this.token);
        this.refKualifikasi = await getRef.LatihanStrukturalKualifi(this.token);
      }
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwdiklat;
      },

      set(value) {
        console.log(value);
        // modal_editState.commit("editModal", value);
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,
    rwdiklat: {},

    // Date Picker
    tglAwalPicker: false,
    tglAkhirPicker: false,
    tglIjazahPicker: false,

    refDiklat: [],
    refKualifikasi: [],

    // UPLOAD FILE
    doc: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.rwdiklat = {
        id: "",
        sapk: {
          id: null,
        },
        latihanStruktural: {
          id: null,
          nama: null,
        },
        nomor: "",
        tanggal: "",
        tahun: "",
        penyelenggara: "",
        tempat: "",
        pelaksanaan: {
          awal: "",
          akhir: "",
          jp: null,
        },
        kualifikasi: {
          id: 0,
          nama: "",
        },
        nilai: "",
        userId: null,
        NCSISTIME: null,
      };
    },

    async edit() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("doc", this.doc);
      data.append("iddiklat", this.editedItem.id);
      data.append("sapk_id", this.editedItem.sapk_id);
      data.append("latstruk_id", this.editedItem.latihanStruktural.id);
      data.append("nomor", this.editedItem.nomor);
      data.append("tanggal", this.editedItem.tanggal);
      data.append("tahun", this.editedItem.tahun);
      data.append("penyelenggara", this.editedItem.penyelenggara);
      data.append("tempat", this.editedItem.tempat);
      data.append("tgl_awal", this.editedItem.pelaksanaan.awal);
      data.append("tgl_akhir", this.editedItem.pelaksanaan.akhir);
      data.append("jumjam", this.editedItem.pelaksanaan.jp);
      data.append("kualifikasi_id", this.editedItem.kualifikasi.id);
      data.append("nilai", this.editedItem.nilai);

      const url =
        process.env.VUE_APP_ASN +
        "diklat/nip/" +
        this.get_nip +
        "?iddiklat=" +
        this.editedItem.id;

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    onUpload(value) {
      this.doc = value;
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit(
        "editModal",
        Object.assign({}, this.rwdiklat)
      );
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },
  },
};
</script>
