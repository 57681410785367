import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,

        dataUtama: {
            id: '',
            SAPK: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },

        rwdiklat: {}
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },

        tosimasnModal(state, value) {
            state.rwdiklat = value
        },

        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})