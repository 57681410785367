import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwdiklat: {
      id: '',
      sapk: {
        id: null
      },
      latihanStruktural: {
        id: null,
        nama: null
      },
      nomor: '',
      tanggal: '',
      tahun: '',
      penyelenggara: '',
      tempat: '',
      pelaksanaan: {
        awal: '',
        akhir: '',
        jp: null
      },
      kualifikasi: {
        id: 0,
        nama: ''
      },
      nilai: '',
      userId: null,
      NCSISTIME: null
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwdiklat = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
