<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwdiklat_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwdiklatModalToSIMASN />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toSIMASN(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-database-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Salin Data</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiURL from '@/helper/getURL.js'
import Cookie from '@/helper/cookie.js'
import Auth from '@/helper/auth.js'

import refreshView from '@/store/asn/rwdiklat/tabs'
import refreshData from '@/store/asn/rwdiklat/viewRwdiklat'
import modal_tosimasnState from '@/store/asn/rwdiklat/modal_to_simasn'

import rwdiklatModalToSIMASN from '@/components/asn/profil/rwdiklat/ModalToSIMASN'

export default {
  components: {
    rwdiklatModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK
      },
    },

    refresh: {
      get() {
        return refreshData.state.RefreshSAPK
      },
    },

    alertMassage: {
      get() {
        return refreshData.state.alertMassage
      },
    },

    success: {
      get() {
        return refreshData.state.success
      },

      set(value) {
        refreshData.commit('alert', value)
      },
    },

    alertBerhasil: {
      get() {
        return refreshData.state.alertBerhasil
      },

      set(value) {
        refreshData.commit('berhasilAlert', value)
      },
    },

    alertGagal: {
      get() {
        return refreshData.state.alertGagal
      },

      set(value) {
        refreshData.commit('gagalAlert', value)
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK()
      }
    },
  },

  data: () => ({
    Modal_rwdiklat_SAPK: false,
    loading: false,

    rwdiklat_SAPK: [],
    get_nip: '',

    headers: [
      { text: 'Nama Diklat', align: 'start', value: 'latihanStruktural.nama' },
      { text: 'Nomor', value: 'nomor' },
      { text: 'Tanggal', value: 'tanggal', width: '200px' },
      { text: 'Tahun', value: 'tahun' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id
    if (this.tabSAPK) {
      this.getDataSAPK()
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true
      const token = Cookie.get('token')
      const url = apiURL.baseURL + 'diklat/nip/' + this.get_nip + '/bkn'

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.rwdiklat_SAPK = response.data.mapData.RwDiklat
          this.loading = false
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status)
          } else {
            console.log('Error', error.message)
          }
        })
    },

    toSIMASN(item) {
      modal_tosimasnState.commit('toggleModal', true)
      modal_tosimasnState.commit('tosimasnModal', item)
      modal_tosimasnState.commit('Datautama', this.dataUtama)
    },
  },
}
</script>
