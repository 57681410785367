<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Tambah Riwayat Diklat </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Diklat -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Diklat</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  v-model="latstruk_id"
                  :items="refDiklat"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal / Jam Pelaksanaan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal / Jam Pelaksanaan</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="tempatPelaksanaan"
                  hint="Tempat Pelaksanaan"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAwalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl_awal"
                      hint="Tanggal Awal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl_awal"
                    @input="tglAwalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAkhirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl_akhir"
                      hint="Tanggal Akhir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl_akhir"
                    @input="tglAkhirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="jumjam"
                  hint="Jam"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- penyelenggara -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>penyelenggara</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="penyelenggara"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nomor/ Tanggal Ijazah -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nomor/ Tanggal Ijazah</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="nomorIjazah"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglIjazahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglIjazah"
                      hint="Tanggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglIjazah"
                    @input="tglIjazahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="thnIjazah"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kualifikasi / Nilai Rata-rata -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kualifikasi / Nilai Rata-rata</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-model="kualifikasi_id"
                  hint="Kualifikasi"
                  persistent-hint
                  :items="refkualifikasi_id"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="nilai"
                  hint="Nilai"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>

          <!-- Upload File -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-5">
                <span>Upload</span>
              </v-col>
              <v-col cols="5">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="onUpload"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()">
              SIMPAN
            </v-btn>
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/asn/rwdiklat/viewRwdiklat";

export default {
  name: "rwdiklatModalAdd",

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refDiklat = await getRef.LatihanStruktural(this.token);
        this.refkualifikasi_id = await getRef.LatihanStrukturalKualifi(
          this.token
        );
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    ModalAdd: false,
    btnLoading: true,

    // Date Picker
    tglAwalPicker: false,
    tglAkhirPicker: false,
    tglIjazahPicker: false,

    // Select
    refDiklat: [],
    refkualifikasi_id: [],

    // variable
    latstruk_id: "",
    tempatPelaksanaan: "",
    tgl_awal: "",
    tgl_akhir: "",
    jumjam: "",
    penyelenggara: "",
    nomorIjazah: "",
    tglIjazah: "",
    thnIjazah: "",
    kualifikasi_id: "",
    nilai: "",

    // UPLOAD FILE
    doc: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    async add() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("doc", this.doc);
      data.append("latstruk_id", this.latstruk_id);
      data.append("nomor", this.nomorIjazah);
      data.append("tanggal", this.tglIjazah);
      data.append("tahun", this.thnIjazah);
      data.append("penyelenggara", this.penyelenggara);
      data.append("tempat", this.tempatPelaksanaan);
      data.append("tgl_awal", this.tgl_awal);
      data.append("tgl_akhir", this.tgl_akhir);
      data.append("jumjam", this.jumjam);
      data.append("kualifikasi_id", this.kualifikasi_id);
      data.append("nilai", this.nilai);

      const url = process.env.VUE_APP_ASN + "diklat/nip/" + this.get_nip;
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    onUpload(value) {
      this.doc = value;
    },

    closeModal() {
      this.ModalAdd = false;
    },
  },
};
</script>
