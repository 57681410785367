import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tabSIMASN: false,
    tabSAPK: false,
  },

  mutations: {
    getSIMASN(state, value) {
      state.tabSIMASN = value
    },
    getSAPK(state, value) {
      state.tabSAPK = value
    }
  },
})
